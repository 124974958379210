<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Admin dashboard</v-toolbar-title>
      </v-toolbar>
    </v-card>
    <v-card flat color="blue-grey lighten-5">
      <v-container fluid>
        <p>
          SMS sender balance: <span class="text-subtitle-2">{{ adminBalance.turbo_sms_balance }}</span> грн
        </p>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'AdminMain',
  components: { BackButton },
  computed: {
    ...mapState('admin', ['adminBalance']),
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapActions('admin', ['getAdminBalance']),
    initialize() {
      this.getAdminBalance()
    },
  },
}
</script>

<style scoped></style>
